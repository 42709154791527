import { createGlobalStyle } from 'styled-components';

import bulma from './bulmaSetup.scss';
import reset from './reset.css';

const GlobalStyle = createGlobalStyle`
	@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:400,500&display=swap');
	@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,400i,700&display=swap');

	${reset}
	${bulma}

	* {
		box-sizing: border-box;
	}

	*:focus {
		outline: 2px solid black;
	}

	html {
		font-size: 16px;
	}

	body {
		font-size: 16px;
		background-color: #fff;
		font-family: ${(props) => props.theme.fonts.body} !important;
		color: ${(p) => p.theme.color.grey};
		h1 {
			font-size: 2.5rem;
			line-height: 2.8rem;
			font-family: ${(p) => p.theme.fonts.heading};
			color: ${(p) => p.theme.color.headingGrey};
		} 
		h2 {
			font-size: 1.5rem;
			line-height: 1.5;
			text-transform: uppercase;
			color: ${(p) => p.theme.color.headingGrey};
		}
		h3 {
			font-weight: 700;
			font-size: 1.2rem;
			line-height: 1.7rem;
		}
		p {
			line-height: 2rem;
		}
		a, button {
			color: ${(p) => p.theme.color.red};
				transition: .5s;
			&:hover {
				color: ${(p) => p.theme.color.purple};
			}
			&.redButton {
				background-color: ${(p) => p.theme.color.red};
				color: #fff;
				padding: .5rem 1.5rem;
				border-radius: 20px;
				font-weight: 700;
				font-family: ${(p) => p.theme.fonts.heading};
				border-radius: 20px;
				border: none;
				font-size: 1rem;
				transition: .5s;
				cursor: pointer;
				&:hover {
					background-color: ${(p) => p.theme.color.purple};
				}
			}
		}
		strong {
			font-weight: 700;
			color: ${(p) => p.theme.color.headingGrey};
		}
		input, textarea {
			border: none;
			border-radius: unset;
			font-size: 1rem;
		}
		@media all and (min-width: 1600px) {
			h1 {
				font-size: 4rem;
				line-height: 4.5rem;
			}
			h2 {
				font-size: 2.5rem;
				line-height: 3rem;
			}
			h3 {
				font-size: 1.8rem !important;
				line-height: 2.3rem !important;
			}
			p {
				font-size: 1.5rem
				line-height: 2.5rem;
			}
			li {
				font-size: 1.5rem
				line-height: 2rem;
			}
			a, a.redButton, button.redButton {
				font-size: 1.5rem
				line-height: 2rem;
			 }
		}

	}
	/* Hide content visually, keep it accessible */
	/* Found at: https://gist.github.com/marcol/3979586 */
	.visually-hidden {
		position: absolute !important;
		clip: rect(1px 1px 1px 1px);
		clip: rect(1px, 1px, 1px, 1px);
		padding: 0 !important;
		border: 0 !important;
		height: 1px !important ;
		width: 1px !important ;
		overflow: hidden;
	}
`;

export default GlobalStyle;
