import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

import mdToHtml from '../util/mdToHtml';

const StyledCopy = styled.div`
	background-color: white;
	h1,
	h2,
	p {
		color: black;
		margin-bottom: 1rem;
	}
	p {
		line-height: 1.5;
	}
	ul {
		margin-left: 2rem;
		margin-bottom: 2rem;
	}
	li {
		list-style-type: disc;
		margin-bottom: 1rem;
		color: #222;
	}
	.container {
		padding: 0 2rem;
	}
`;

const PrivacyPolicy = ({ data }) => {
	const content = data.pagesJson;

	return (
		<Layout>
			<SEO
				title="Privacy Policy"
				description="North American Freight Group Privacy Policy"
			/>
			<StyledCopy>
				<div className="container">
					<h1>{content.title}</h1>
					<div dangerouslySetInnerHTML={{ __html: mdToHtml(content.body) }} />
				</div>
			</StyledCopy>
		</Layout>
	);
};

export default PrivacyPolicy;

export const pageQuery = graphql`
	query PrivacyPolicyPage {
		pagesJson(page: { eq: "privacy-policy" }) {
			title
			body
		}
	}
`;
