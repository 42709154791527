import React from 'react';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from '../styles/global';
import theme from '../styles/theme';
import NavMenu from './nav';
import Footer from './footer';
import Popup from './popup';

const Layout = ({ children, short, popup }) => (
	<ThemeProvider theme={theme}>
		<>
			<GlobalStyle />
			<NavMenu />
			{children}
			<Footer short={short} />
			{popup ? <Popup /> : null}
		</>
	</ThemeProvider>
);

export default Layout;
