import styled from 'styled-components';

export default styled.div`
	.navbar {
		padding: 0.5rem 3.5rem;
		@media all and (max-width: 768px) {
			padding: 0.5rem;
		}
		.mobile-overlay {
			height: 100%;
			position: relative;
			top: 0;
			left: 0;
			transition: 0.5s ease;
			opacity: 0;
			background-color: rgba(0, 0, 0, 0.5);
		}
		&.mobileOpen {
			.mobile-overlay {
				opacity: 1;
				position: fixed;
				transition: 0.5s ease;
				width: 100%;
			}
			.navbar-brand a {
				z-index: -1;
			}
			.navbar-burger,
			.top-bar {
				position: fixed;
				top: 0;
			}
		}
		.mobile-logo {
			display: none;
		}
		.hiddenButtonHoverImage {
			opacity: 0 !important;
			position: absolute;
		}
		.navbar-brand a {
			padding: 0;
			position: relative;
		}
		.navbar-item {
			background-color: #fff !important;
		}
		a,
		p.navbar-link {
			color: ${(p) => p.theme.color.headingGrey};
			font-family: Barlow Condensed, sans-serif;
			text-transform: uppercase;
			padding: 0 0.8rem;
			&:hover {
				color: ${(p) => p.theme.color.purple};
			}
		}
		.logo-container {
			width: 220px;
			position: relative;
			top: -20px;
			img {
				max-height: none;
			}
		}
		margin-top: 30px;
		.top-bar {
			top: -30px;
			position: absolute;
			right: 0;
			display: flex;
			.contact {
				display: flex;
				align-items: center;
				color: #fff;
				background-color: ${(p) => p.theme.color.red};
				.social {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 0.4rem;
					a {
						padding: 0 5px;
						width: 35px;
						height: 35px;
						display: flex;
						align-items: center;
						justify-content: center;
						img {
							position: absolute;
							width: 30px;
							height: 30px;
							&.linkedin {
								width: 40px;
								height: 40px;
							}
						}
						&:hover {
							opacity: 0.6;
						}
					}
				}
				p {
					color: #fff;
					padding-left: 1rem;
					border-left: 2px solid #fff;
					margin: 5px 0;
					font-weight: 600;
					font-family: ${(p) => p.theme.fonts.heading};
					a {
						color: #fff;
						position: relative;
						padding: 0;
						margin: 0 0.8rem;
						&::after {
							content: '';
							background-color: #fff;
							transition: 0.5s;
							width: 100%;
							height: 1px;
							position: absolute;
							bottom: 0;
							left: 0;
							right: 0;
							opacity: 0;
						}
						&:hover::after {
							opacity: 1;
						}
					}
				}
			}
			.tracking {
				background-color: ${(p) => p.theme.color.purple};
				display: flex;
				align-items: center;
				p {
					color: #fff;
					font-weight: 600;
					font-family: ${(p) => p.theme.fonts.heading};
					padding: 0 1rem;
				}
				form {
					padding: 0;
					margin-right: 5px;
					input {
						width: 200px;
						height: 25px;
						padding-left: 5px;
						&::placeholder {
							font-style: italic;
							font-family: ${(p) => p.theme.fonts.heading};
						}
					}
					button {
						cursor: pointer;
						appearance: none;
						margin: 0;
						margin-left: 10px;
						border: none;
						font-size: 0.9rem;
						height: 25px;
						width: 35px;
						position: relative;
						top: -1px;
						color: #fff;
						background-color: ${(p) => p.theme.color.red};
						@media all and (min-width: 1600px) {
							font-size: 1.1rem;
							top: 0;
							width: 40px;
						}
					}
				}
			}
		}
		@media all and (min-width: 1600px) {
			padding: 1rem 5rem;
			.logo-container {
				width: 280px;
			}
			a {
				font-size: 1.3rem;
				line-height: 1.5rem;
				padding: 0 2rem;
			}
			.top-bar {
				p {
					font-size: 1.3rem;
				}
			}
		}
		.navbar-item.has-dropdown {
			display: none;
		}
		@media all and (max-width: 1050px) {
			.top-bar {
				z-index: 999;
			}
			.collapse {
				display: none;
			}
			.navbar-item.has-dropdown {
				display: flex;
				z-index: 99;
				position: relative;
				.navbar-dropdown {
					display: block;
					position: absolute;
					opacity: 0;
					top: 0%;
					transition: top 0.5s, opacity 0.5s cubic-bezier(0, 0.7, 0, 1);
					z-index: -1;
					.navbar-item {
						z-index: -1;
						position: relative;
					}
				}
				p.navbar-link {
					padding-right: 2.5rem;
					&::after {
						border-color: ${(p) => p.theme.color.headingGrey};
					}
				}
				&:hover {
					p.navbar-link {
						background-color: #fff;
						color: ${(p) => p.theme.color.purple};
					}
					.navbar-dropdown {
						opacity: 1;
						top: 100%;
						transition: 0.5s;
						border-top: none;
						.navbar-item {
							font-size: 1rem;
							padding: 0.4rem 1rem;
						}
					}
				}
			}
		}
		@media all and (max-width: 878px) {
			padding: 0.1rem 4rem;
			.mobile-logo {
				display: flex;
			}
			.navbar-item.has-dropdown {
				display: none;
			}
			.collapse {
				display: flex;
			}
			.logo-container {
				width: 150px;
			}
			.top-bar {
				width: 100%;
				flex-wrap: wrap;
				.contact,
				.tracking {
					width: 50%;
					justify-content: center;
				}
			}
			.navbar-brand {
				margin-top: 35px;
			}
			.navbar-menu {
				display: block;
				padding: 1rem 4rem 2rem 0.5rem;
				position: fixed;
				right: -440px;
				top: 35px;
				opacity: 0;
				transition: right 0.5s cubic-bezier(0.08, 1, 0.36, 1),
					opacity 0s ease-in 0.5s;
				&.is-active {
					transition: right 0.5s cubic-bezier(0.08, 1, 0.36, 1), opacity 0s;
					opacity: 1;
					right: 0px;
				}
				.navbar-item {
					font-size: 2rem;
					padding: 0.3rem 1rem;
				}
				.mobile-logo {
					width: 250px;
					display: block;
					margin-bottom: 1rem;
					img {
						max-height: none;
					}
				}
			}
			.navbar-burger {
				position: absolute;
				right: 30px;
				top: 25px;
				z-index: 999;
				transform: translateY(-5px);
				transition: transform 86ms ease-out;
				background: none;
				&.is-active {
					top: 60px;
					transform: none;
					p {
						opacity: 0;
						transform: translateY(-5px);
					}
					span {
						&:nth-child(1) {
							transform: translateY(6px) rotate(45deg);
						}
						&:nth-child(3) {
							transform: translateY(-18px) rotate(-45deg);
						}
					}
				}
				&:not(.is-active) span:nth-child(1) {
					transform: translateY(5px);
				}
				p {
					font-family: Barlow Condensed, sans-serif;
					position: absolute;
					right: 45px;
					top: 13px;
					font-size: 18px;
					display: block;
					transition: 86ms ease-out;
				}
				span {
					height: 4px;
					width: 32px;
					&:nth-child(1) {
						top: calc(50% - 12px);
					}
					&:nth-child(2) {
						top: calc(50% + 2px);
					}
					&:nth-child(3) {
						top: calc(50% + 12px);
					}
				}
			}
		}
		@media all and (max-width: 650px) {
			.top-bar {
				.contact {
					width: 100%;
				}
				.tracking {
					width: 100%;
					padding: 5px 0;
					form {
						width: 300px;
						input {
							width: calc(100% - 45px);
						}
					}
				}
			}
			.navbar-brand {
				margin-top: 80px;
			}
			.navbar-burger {
				top: 65px;
				&.is-active {
					top: 95px;
				}
			}
			.navbar-menu {
				top: 70px;
			}
		}
		@media all and (max-width: 500px) {
			padding: 0.1rem 2rem;
			.navbar-menu {
				padding: 1rem 1rem 2rem 0.5rem;
				right: -290px;
				.navbar-item {
					line-height: 2.1rem;
					margin: 0.5rem 0;
					&.warehouse {
						width: 220px;
					}
					&.mobile-logo {
						margin: 0;
						width: 220px;
					}
				}
			}
		}
		@media all and (max-width: 430px) {
			.top-bar .tracking form {
				width: 70%;
				input {
					width: calc(100% - 72px);
				}
			}
		}
		@media all and (max-width: 400px) {
			.top-bar .contact {
				.social {
					width: 40%;
				}
				p {
					width: 60%;
				}
			}
		}
		@media all and (max-width: 350px) {
			.top-bar .tracking form {
				width: 60%;
			}
			.navbar-menu {
				padding: 0 4rem 1rem 0.5rem;
				top: 84px;
				&.is-active {
					right: -30px;
				}
				.navbar-end {
					a {
						font-size: 1.7rem !important;
					}
				}
			}
		}
	}

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		.logo-container img {
			width: 220px;
		}
		.top-bar .social a {
			position: relative;
			top: -15px;
			left: -5px;
			.linkedin {
				top: 13px;
				left: 8px;
			}
		}
	}
`;
