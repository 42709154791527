import React, { useState } from 'react';
import { Link } from 'gatsby';

import NavStyle from '../styles/navStyle.js';

const NavMenu = () => {
	const [mobileMenu, setMobileMenu] = useState(false);
	const [trackingNum, setTrackingNum] = useState('');

	return (
		<NavStyle>
			<nav className={mobileMenu ? 'navbar mobileOpen' : 'navbar'}>
				<div
					className="mobile-overlay"
					onClick={() => {
						if (!mobileMenu) setMobileMenu(true);
						else setMobileMenu(false);
					}}
				/>
				<div className="navbar-brand" onClick={(e) => e.stopPropagation()}>
					<Link className="navbar-item logo-container" to="/">
						<img
							src="/img/NAFG-logo.png"
							alt="North American Freight Group Logo"
						/>
					</Link>
					<div
						role="button"
						className={mobileMenu ? 'navbar-burger is-active' : 'navbar-burger'}
						onClick={() => {
							if (!mobileMenu) setMobileMenu(true);
							else setMobileMenu(false);
						}}
					>
						<span />
						<span />
						<span />
						<p>MENU</p>
					</div>
				</div>
				<div className={mobileMenu ? 'navbar-menu is-active' : 'navbar-menu'}>
					<div className="navbar-end">
						<Link className="mobile-logo navbar-item" to="/">
							<img
								src="/img/NAFG-logo.png"
								alt="North American Freight Group Logo"
							/>
						</Link>
						<Link className="navbar-item collapse" to="/freight/">
							Freight
						</Link>
						<Link className="navbar-item collapse" to="/freight-forwarding/">
							Freight Forwarding
						</Link>
						<Link className="navbar-item collapse warehouse" to="/warehousing/">
							Warehousing & Distribution
						</Link>
						<Link className="navbar-item collapse" to="/customs/">
							Customs
						</Link>
						<div className="navbar-item has-dropdown">
							<p className="navbar-link">Services</p>
							<div className="navbar-dropdown">
								<Link className="navbar-item" to="/freight/">
									Freight
								</Link>
								<Link className="navbar-item" to="/freight-forwarding/">
									Freight Forwarding
								</Link>
								<Link className="navbar-item" to="/warehousing/">
									Warehousing & Distribution
								</Link>
								<Link className="navbar-item" to="/customs/">
									Customs
								</Link>
							</div>
						</div>
						<Link className="navbar-item" to="/careers/">
							Careers
						</Link>
						{/* <Link className="navbar-item" to="/news/">
							News
						</Link> */}
						<Link className="navbar-item" to="/contact/">
							Contact
						</Link>
					</div>
				</div>
				<div className="top-bar">
					<div className="contact">
						<div className="social">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://twitter.com/nafreightgroup"
								aria-label="Twitter - new window"
							>
								<img
									className="twitter"
									src="/img/twitter-icon.svg"
									alt="twitter icon"
								/>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.facebook.com/northamericanfreightgroup/"
								aria-label="Facebook - new window"
							>
								<img
									className="facebook"
									src="/img/facebook-icon.svg"
									alt="facebook icon"
								/>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.linkedin.com/company/north-american-freight-forwarding-inc/"
								aria-label="LinkedIn - new window"
							>
								<img
									className="linkedin"
									src="/img/linkedin-icon.svg"
									alt="linkedin icon"
								/>
							</a>
						</div>
						<p>
							CALL US: <a href="tel:+19052199936">905.219.9936</a>
						</p>
					</div>
					<div className="tracking">
						<p role="heading" aria-level="2">TRACK:</p>

						<form
							action={`https://saahstwebtracker.wisegrid.net/Login/Login.aspx?QuickViewNumber=${trackingNum}`}
							method="POST"
							type="text"
							target="_blank"
							aria-label="Track - new window"
						>
							<label htmlFor="quickviewnumber" type="hidden">
								<input
									//name="quickviewnumber"
									//id="quickviewnumber"
									placeholder="Tracking Number"
									onChange={(e) => {
										setTrackingNum(e.target.value);
									}}
									aria-label="tacking number"
								/>
							</label>
							<button type="submit">GO</button>
						</form>
					</div>
				</div>
			</nav>
		</NavStyle>
	);
};

export default NavMenu;
