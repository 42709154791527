import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledFooter = styled.footer`
	background-color: #fff;
	&.short {
		position: absolute;
		bottom: 0;
		width: 100%;
		@media all and (max-width: 800px) {
			position: relative;
		}
	}
	.footer-top {
		background-color: #fff;
		padding: 2rem ${(p) => p.theme.gutter};
		display: flex;
		.img-container {
			flex: 1;
			display: flex;
			align-items: center;
			margin-right: 5%;
			a {
				margin-left: auto;
			}
		}
		.copy {
			flex: 2;
			display: flex;
			align-items: center;
		}
	}
	.logos {
		background-color: ${(p) => p.theme.color.purple};
		.logos-container {
			display: flex;
			justify-content: space-around;
			align-items: center;
			padding: 1.5rem ${(p) => p.theme.gutter};
			a {
				width: 25%;
				display: flex;
				align-items: flex-end;
				padding: 0 1rem;
				justify-content: center;
				&.ciffa {
					justify-content: flex-start;
					div {
						width: 85%;
					}
				}
				&.fma {
					padding: 0 2rem;
					margin: 0 -1rem;
				}
				&:hover {
					opacity: 0.75;
				}
			}
		}
	}
	.footer-bottom {
		background-color: ${(p) => p.theme.color.red};
		color: #fff;
		padding: 5px;
		a, small {
			color: white;
			line-height: 2rem;
			margin-left: 16px;
			font-size: 1rem !important;
		}
		.columns {
			margin-bottom: -5px;
		}
		.container {
			padding: 0 2rem;
		}
	}
	@media all and (min-width: 1200px) {
		.footer-top {
			padding: 2rem ${(p) => p.theme.gutterWide};
		}
		.logos .logos-container {
			padding: 1rem ${(p) => p.theme.gutterWide};
		}
	}
	@media all and (min-width: 1600px) {
		.footer-top {
			padding: 3rem ${(p) => p.theme.gutterXWide};
			.copy {
				flex: 3;
			}
		}
		.logos .logos-container {
			padding: 2rem ${(p) => p.theme.gutterXWide};
		}
	}
	@media all and (max-width: 1100px) {
		.logos {
			margin-bottom: -1rem;
			.logos-container {
				flex-wrap: wrap;
				a {
					width: 25%;
					text-align: center;
					margin: 0.5rem 0 2rem;
					&.ciffa {
						margin: 1.5rem 0 2rem;
						justify-content: center;
					}
					&.fma {
						padding: 0;
						margin: 0.5rem 0 2rem;
					}
				}
			}
		}
	}
	@media all and (max-width: 850px) {
		.footer-top {
			flex-direction: column;
			.img-container {
				flex: 1;
				margin: 0 auto;
				width: 390px;
				a {
					margin: 0 auto 1rem;
				}
			}
			.copy {
				flex: 1;
			}
		}
	}
	@media all and (max-width: 768px) {
		.logos .logos-container a {
			width: 50%;
			&.ciffa {
				padding: 0 2rem;
			}
		}
	}
	@media all and (max-width: 600px) {
		.footer-top .img-container,
		.logos .logos-container a.ciffa div {
			width: 60%;
		}
	}
	@media all and (max-width: 550px) {
		.logos .logos-container a.ciffa {
			padding: 0 10px;
			margin: 0.5rem 0 1rem;
		}
	}
	@media all and (max-width: 500px) {
		.footer-top {
			padding: 2rem ${(p) => p.theme.gutterMobile} 3rem;
			p {
				line-height: 1.6rem;
			}
			.img-container {
				width: 75%;
			}
		}
		.logos .logos-container {
			padding: 2rem ${(p) => p.theme.gutterMobile};
			a.ciffa {
				justify-content: flex-start;
				div {
					width: 90%;
				}
			}
		}
	}
	@media all and (max-width: 400px) {
		.logos .logos-container a.fma {
			padding: 0 1rem;
		}
	}

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		.footer-top img {
			width: 250px;
		}
	}
`;

const logos = [
	{
		link: 'https://www.iata.org/',
		img: '/img/iata-logo.png',
		alt: 'iata logo',
	},
	{
		link: 'https://www.ciffa.com/',
		class: 'ciffa',
		img: '/img/ciffa-logo.png',
		alt: 'ciffa logo',
	},
	{
		link: 'http://www.carvre7.com/',
		img: '/img/carvre_sevenlogo.png',
		alt: 'carvre logo',
	},
	{
		link: 'https://www.cbsa-asfc.gc.ca/security-securite/pip-pep/',
		img: '/img/pip_logo.png',
		alt: 'partners in protection logo',
	},
];

const Footer = ({ short }) => {
	return (
		<StyledFooter className={short ? 'short' : ''}>
			<div className="footer-top">
				<div className="img-container">
					<Link to="/">
						<img
							src="/img/NAFG-logo.png"
							alt="North American Freight Group Logo"
						/>
					</Link>
				</div>
				<div className="copy">
					<p>
						Our Freight Forwarding services are provided in accordance with the
						standard Trading Conditions set by the Canadian International
						Freight Forwarding Association (CIFFA). To view these terms, please
						visit the <a href="https://www.ciffa.com/">CIFFA’s website.</a>
					</p>
				</div>
			</div>
			{!short && (
				<div className="logos">
					<div className="logos-container">
						{logos.map((x, i) => (
							<a href={x.link} className={x.class} key={i}>
								<img src={x.img} alt={x.alt} />
							</a>
						))}
					</div>
				</div>
			)}
			<div className="footer-bottom">
				<div className="container">
					<div className="columns">
						<div className="column" />
						<div className="column has-text-centered">
							<small>&copy; 2019, North American Freight Group</small>
						</div>
						<div className="column has-text-right has-text-centered-mobile">
							<a href="/DOC-004 Accessibility Policy (Rev.1).pdf" target="_blank" aria-label="Accessibility Policy - new window">Accessibility Policy</a>
							<a href="/Multi-Year Accessibility Plan (AODA).pdf"target="_blank" aria-label="Accessibility Plan - new window">Accessibility Plan</a>
							<Link to="/privacy-policy">Privacy Policy</Link>
						</div>
					</div>
				</div>
			</div>
		</StyledFooter>
	);
};

export default Footer;
