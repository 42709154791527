import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Modal from 'react-modal';

import mdToHtml from '../util/mdToHtml';

const ModalPane = styled.div`
	position: relative;
	background-color: rgba(169, 30, 33, 0.9);
	color: white;
	text-align: center;
	font-size: 28px;

	h2 {
		color: inherit;
		font-weight: bold;
		font-size: inherit;
	}

	.modal-inner {
		max-width: 20em;
		margin: 0 auto;
		padding: 0 10px 60px;

		.modal-text {
			* {
				font-weight: 100;
				color: inherit;
			}

			p {
				margin-bottom: 0.5em;
			}

			strong {
				font-weight: bold;
			}

			em {
				font-style: italic;
			}

			a {
				text-decoration: underline;
			}

			ul {
				margin: 0.5em 0;
				list-style: disc;
				list-style-position: inside;
			}
		}
	}

	.close-button-outer {
		position: sticky;
		top: 4px;
	}
	.close-button-wrapper {
		text-align: right;
		padding: 0 10px 0;
	}

	.close-button {
		position: relative;
		width: 35px;
		height: 35px;
		background-color: #72080c;
		border: none;
		border-radius: 50%;
		color: white;
		cursor: pointer;

		&::before,
		&::after {
			content: '';
			height: 1px;
			width: 81%;
			background-color: white;
			position: absolute;
			top: 50%;
			left: 50%;
			transform-origin: center;
		}

		&::before {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}
`;

Modal.setAppElement('#___gatsby');

const Popup = () => {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const data = useStaticQuery(graphql`
		query PopupQuery {
			adminJson {
				popup_settings {
					body
					is_active
					title
				}
			}
		}
	`);

	useEffect(() => {
		setTimeout(() => {
			openModal();
		}, 3000);
	}, []);

	const { popup_settings: popupSettings } = data.adminJson;

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	const modalStyles = {
		overlay: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: '30',
			backgroundColor: 'rgba(255,255,255,0)',
			padding: '60px 20px',
		},
		content: {
			border: 'none',
			borderBottom: `10px solid #1a0b46`,
			borderRadius: '0',
			padding: '0',
			position: 'static',
			inset: 'unset',
			width: '700px',
			maxWidth: '100%',
			backgroundColor: 'rgba(255,255,255,0)',
			overflow: 'scroll',
			maxHeight: '100%',
		},
	};

	return popupSettings.is_active ? (
		<Modal
			isOpen={modalIsOpen}
			onRequestClose={closeModal}
			contentLabel="Alert Modal"
			style={modalStyles}
			shouldCloseOnEsc={true}
			onAfterClose={closeModal}
		>
			<ModalPane>
				<div className="close-button-outer">
					<div className="close-button-wrapper">
						<button className="close-button" onClick={closeModal}>
							<span className="visually-hidden">Close Modal</span>
						</button>
					</div>
				</div>
				<div className="modal-inner">
					<h2>{popupSettings.title}</h2>
					<div
						className="modal-text"
						dangerouslySetInnerHTML={{ __html: mdToHtml(popupSettings.body) }}
					/>
				</div>
			</ModalPane>
		</Modal>
	) : null;
};

export default Popup;
